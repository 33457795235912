import { Injectable } from '@angular/core';
import { PollAnswerModel } from '../models/poll-answer-model';
import { Observable } from 'rxjs';
import { APILinkerService } from 'src/app/common/services/apilinker.service';
import { appConfig } from 'src/app/config/app-config';
import { HttpParams } from '@angular/common/http';
import { SellingServiceModel } from '../../selling-services/models/selling-service-model';
import { DefaultMessagesSenderService } from 'src/app/common/services/default-messages-sender.service';
import { PollServiceAnswerInterface } from '../models/poll-service-answer-interface';
import { createOfflineCompileUrlResolver } from '@angular/compiler';
import { ServiceTypeModel } from '../../selling-services/models/service-types/service-type-model';
import { APIParamModel } from 'src/app/common/models/api-param-model';
import { ExportToCsv } from 'export-to-csv';
import { FormatParserService } from 'src/app/common/services/format-parser.service';

@Injectable({
  providedIn: 'root'
})
export class PollsService {

  private sendAnswersObservable: Observable<boolean>;
  private sendEmailObservable: Observable<boolean>;

  private sendAnswersObservableStatus: boolean;
  private sendEmailObservableStatus: boolean;

  constructor(
    private apiLinkerService: APILinkerService,
    private defaultMessagesSenderService: DefaultMessagesSenderService,
    private formatParserService: FormatParserService,

  ) { 
    this.sendAnswersObservableStatus = false;
  }

  sendAnswers(answers: Array<PollAnswerModel>): Observable<boolean>{
    if(!this.sendAnswersObservableStatus){
      this.sendAnswersObservable = new Observable<boolean> ((observer) => {
        this.sendAnswersObservableStatus = true;
        const uri = this.apiLinkerService.generateURI(appConfig.endpoints.polls, 'fill');
        const httpOptions = this.apiLinkerService.generateHttpOptions(new HttpParams());
        const payload = {
          answers: answers.map( (answer) => {
            return answer.parse();
          })
        }

        const callback = (freshData) => {
          if(!!freshData && !!freshData.response){
            if(freshData.response.message === 'SAVED_SUCCESSFULLY'){
              observer.next(true);
              observer.complete();
            } else {
              this.sendAnswersObservableStatus = false;
              observer.error(freshData);
            }
          } else {
            this.sendAnswersObservableStatus = false;
            observer.error(freshData);
          }
        }

        this.apiLinkerService.postData(uri,payload,httpOptions).subscribe(
          callback,
          (err) => {
            this.sendAnswersObservableStatus = false;
            observer.error(err)
          }
        )
      });
    }
    return this.sendAnswersObservable;
  }

  sendEmail(sellingService: SellingServiceModel, destinataries: Array<string>): Observable<boolean> {
    if(!this.sendEmailObservableStatus){
      this.sendEmailObservable = new Observable((observer) => {
        this.sendEmailObservableStatus = true;
        const httpOptions = this.apiLinkerService.generateHttpOptions(new HttpParams());
        const uri = this.apiLinkerService.generateURI(appConfig.endpoints.polls, 'send-email');
        const payload = {
          environment: appConfig.environment,
          selling_service_id: sellingService.id,
          destinataries: destinataries
        };

        const callback = (freshData) => {
          if(!!freshData && !!freshData.response){
            const response = freshData.response;
            if(response.message === "EMAIL_SENT_SUCCESSFULLY"){
              this.sendEmailObservableStatus = false;
              observer.next(true);
              observer.complete();
            } else {
              this.sendEmailObservableStatus = false;
              observer.next(false);
              observer.error(freshData);
            }
          } else {
            this.sendEmailObservableStatus = false;
              observer.next(false);
              observer.error(freshData);
          }
        };

        this.apiLinkerService.postData(uri,payload,httpOptions).subscribe(callback, (err) => {
          this.sendEmailObservableStatus = false;
          observer.error(err);
        });
      });
    }
    return this.sendEmailObservable;
  }
  
  getAnswers(selectedServiceType: ServiceTypeModel, clientName: string, pollCompletedDateBegin: string, pollCompletedDateEnd: string): Observable <SellingServiceModel> {
    return new Observable( (observer) => {
      const uri = this.apiLinkerService.generateURI(appConfig.endpoints.polls, 'reporting');
      const httpOptions = this.apiLinkerService.generateHttpOptions(new HttpParams());
      
      const params = <APIParamModel[]> [
        {
          key: 'clientName',
          value: clientName
        },
      ];

      if(!!pollCompletedDateBegin) 
        params.push({
          key: 'pollCompletedDateBegin',
          value: pollCompletedDateBegin
        });

      if(!!pollCompletedDateEnd) 
        params.push({
          key: 'pollCompletedDateEnd',
          value: pollCompletedDateEnd
        });

      if(selectedServiceType) {
        params.push({
          key: 'serviceType',
          value: selectedServiceType.id
        })
      }

      const successCallback = (data) => {
        if(!!data && !!data.response.answers){
          data.answers = data.response.answers;
          const mappedServiceQuestionsAnswers = data.answers.forEach( freshService => {
            const newService = new SellingServiceModel();
            newService.map(freshService);
            observer.next(newService);
          });
          observer.complete();
        }
      }

      const errorCallback = (err) => {
        observer.error(err);
      }

      const subscriber = this.apiLinkerService.getData( uri, httpOptions, params)
        .subscribe(successCallback, errorCallback, () => { subscriber.unsubscribe()});
    });
  } 

  generateCSVReport(selectedServiceType: ServiceTypeModel, questions: string[], sellingServices: SellingServiceModel[]){
    const data = sellingServices.map( sellingService => {
      let newRow = {}
      let sumatory = 0;
      let totalQuestions = 0;

      newRow['Orden de servicio'] = sellingService.sapSellOrder;
      newRow['Cliente'] = sellingService.clientName;
      newRow['Area'] = sellingService.area;
      newRow['Enviada'] = this.formatParserService.parseDateToMexicanFormat(sellingService.pollSentDate);
      newRow['Contestada'] = this.formatParserService.parseDateToMexicanFormat(sellingService.pollCompletedDate);
      newRow['Calificacion'] = 0;

      sellingService.pollAnswers.forEach( answer => {
        if(answer.questionType == 1){
          newRow[answer.question] = answer.numericAnswer;
          sumatory += answer.numericAnswer;
          totalQuestions++;
        }
      });

      newRow['Calificacion'] = (sumatory/totalQuestions).toFixed(2);

      return newRow;
    })
     
    let headers = [
      'Orden de servicio',
      'Cliente',
      'Area',
      'Enviada',
      'Contestada',
      'Calificacion',
    ];

    questions.forEach( question => { headers.push(question.replace(",", ""))});
    
    const options = { 
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true, 
      showTitle: false,
      filename: 'Concentrado de encuestas para servicio de ' + selectedServiceType.name.toLocaleLowerCase() + '.csv',
      useTextFile: false,
      useBom: true,
      headers: headers
    };
     
    const csvExporter = new ExportToCsv(options);
     
    csvExporter.generateCsv(data);
  }
}
