import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { appConfig } from 'src/app/config/app-config';
import { TechnicianModel } from '../models/technician-model';
import { APILinkerService } from 'src/app/common/services/apilinker.service';

@Injectable({
  providedIn: 'root'
})
export class TechnicianService {

  private createTechnicianObservable: Observable<TechnicianModel>;
  private updateTechnicianObservable: Observable<TechnicianModel>;
  private getTechnicianInformationObservable: Observable<TechnicianModel>;
  
  private createTechnicianObservableStatus: boolean;
  private updateTechnicianObservableStatus: boolean;
  private getTechnicianInformationObservableStatus: boolean;

  constructor(
    private apiLinkerService: APILinkerService
  ) { }

  public createTechnician(technician: TechnicianModel): Observable<TechnicianModel>{
    if(!this.createTechnicianObservableStatus){
      this.createTechnicianObservable = new Observable<TechnicianModel>((observer) => {
        this.createTechnicianObservableStatus = true;

        let httpOptions = this.apiLinkerService.generateHttpOptions(new HttpParams());
        let uri = this.apiLinkerService.generateURI(appConfig.endpoints.technicians);
        let payload = technician.parse();

        let apiSubscriber = this.apiLinkerService.postData(uri,payload,httpOptions).subscribe((data: any) => {
          let freshData = data.response;
          if(!!freshData){
            freshData = freshData.technician;
            let newProject = new TechnicianModel();
            newProject.map(freshData);

            observer.next(newProject);
            observer.complete();

            this.createTechnicianObservableStatus = false;
            apiSubscriber.unsubscribe();
          } else {
            this.createTechnicianObservableStatus = false;
            observer.error(freshData);
          }
        }, (err) => {
          observer.error(err);
        });
      });
    }
    return this.createTechnicianObservable;
  }

  public updateTechnican(project: TechnicianModel): Observable<TechnicianModel>{
    if(!this.updateTechnicianObservableStatus){
      this.updateTechnicianObservable = new Observable<TechnicianModel>((observer) => {
        this.updateTechnicianObservableStatus = true;

        let httpOptions = this.apiLinkerService.generateHttpOptions(new HttpParams());
        let uri = this.apiLinkerService.generateURI(appConfig.endpoints.technicians, project.id.toString());
        let payload = project.parse();

        let apiSubscriber = this.apiLinkerService.putData(uri, payload, httpOptions).subscribe((data: any) => {
          let freshData = data.response;
          if(!!freshData){
            let freshProject = freshData.technician;

            let updatedProject = new TechnicianModel();
            updatedProject.map(freshProject);

            observer.next(updatedProject);
            observer.complete();

            this.updateTechnicianObservableStatus = false;
            apiSubscriber.unsubscribe();
          } else {
            this.updateTechnicianObservableStatus = false;
            observer.error(freshData);
          }
        },
        (err) => {
          this.updateTechnicianObservableStatus = false;
          observer.error(err);
        });
      });
    }
    return this.updateTechnicianObservable;
  }

  public getTechnicianInformation(technicianId: number): Observable<TechnicianModel>{
    if(!this.getTechnicianInformationObservableStatus){
      this.getTechnicianInformationObservable = new Observable<TechnicianModel>((observer) => {
        this.getTechnicianInformationObservableStatus = true;

        let uri = this.apiLinkerService.generateURI(appConfig.endpoints.technicians, technicianId.toString());
        let httpOptions = this.apiLinkerService.generateHttpOptions(new HttpParams());

        let apiSubscriber = this.apiLinkerService.getData(uri,httpOptions).subscribe((receivedData: any) => {
          let freshData = receivedData.response;
          if(!!freshData){
            freshData = freshData.technician;
            let retrievedProject = new TechnicianModel();
            retrievedProject.map(freshData);

            this.getTechnicianInformationObservableStatus = false;
            observer.next(retrievedProject);
            observer.complete();
          } else {
            this.getTechnicianInformationObservableStatus = false;
            observer.error(freshData);
          }
        }, (err) => {
          this.getTechnicianInformationObservableStatus = false;
          observer.error(err);
        });
      });
    }
    return this.getTechnicianInformationObservable;
  }

  deleteTechnician(technician: TechnicianModel): Observable<TechnicianModel> {
    return new Observable<TechnicianModel>((observer) => {
      const uri = this.apiLinkerService.generateURI(appConfig.endpoints.technicians, technician.id.toString());
      const callback = (freshData) => {
        if(!!freshData.response && freshData.response.message == "DELETED_SUCCESSFULLY"){
          observer.next(technician);
          observer.complete();
        } else {
          observer.error(freshData);
        }
      }

      const err = (err) => {
        observer.error(err);
      }
      this.apiLinkerService.deleteData(uri, this.apiLinkerService.generateHttpOptions(new HttpParams()))
        .subscribe(callback, err);
    })
  }
}
